<template>
  <div class='system-configs-table'>
    <a-table :columns='columns' :data-source='data' row-key='code' :loading='loading' :scroll='{ x: 1200}'
             :pagination='true'>
      <template slot='stt' slot-scope='item, data, index'> {{ index + 1 }}</template>
      <template slot='int_value' slot-scope='item'> {{ numberWithCommas(item.intValue) || 0 }}</template>
      <template slot='actions' slot-scope='item'>
        <a-button type='link' size="small" @click='onShowForm(item)'>
          <a-icon type='form' />
        </a-button>
      </template>
      <template slot='description' slot-scope='item'>
        <span v-if="item.description">{{item.description}}</span>
        <i v-else style="color: #8b8b8b">No description</i>
      </template>
    </a-table>
    <system-configs-form ref='systemConfigsForm' @fetch='fetchData' />
  </div>
</template>

<script>
import SystemConfigsService from '@/services/app/system-configs.service'
import {numberWithCommas} from '@/helpers/common'

export default {
  name: 'SystemConfigsTable',
  components: {
    SystemConfigsForm: () => import('@/components/Pages/SystemConfigs/SystemConfigsForm')
  },
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: 'Order',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'stt' }
        },
        {
          dataIndex: 'code',
          title: 'CODE',
          ellipsis: true
        },
        {
          title: 'Int value',
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: 'int_value' },
          align: 'center',
        },
        {
          title: 'Description',
          ellipsis: true,
          scopedSlots: { customRender: 'description' }
        },
        {
          title: '#',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right',
          width: 140,
          align: 'center'
        }
      ]
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      SystemConfigsService.getList().then(res => {
        if (res.code === 1) {
          this.data = res.data ? [...res.data] : []
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    numberWithCommas,
    onShowForm(item) {
      this.$refs.systemConfigsForm.showModal(item)
    }
  }
}
</script>
