import {http} from '@/plugins/axios/axios'

const getList = () => {
  return http.get('/v1/system-config')
}

const update = (id, payload) => {
  return http.put('/v1/system-config/' + id, payload)
}

export default {
  getList, update
}
